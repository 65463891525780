import { usePage } from "@inertiajs/react";
import Footer from "@/components/nav/footer";
import BaseLayout from "./baseLayout";
import CollaboratorOffboardBanner from "../components/common/banners/collaboratorOffboardBanner";
import Nav from "../components/nav/nav";
import TermsModal from "@/components/common/modals/termsModal";
interface AppLayoutProps {
    children: React.ReactNode;
    title: string;
    description?: string;
    image?: string;
    noterms?: boolean;
}

export default function AppLayout({
    title,
    description,
    image,
    children,
    noterms,
}: AppLayoutProps) {
    const { auth, session } = usePage().props;
    return (
        <BaseLayout title={title} description={description} image={image}>
            {auth.user &&
                auth.user?.collaborator?.licenseIsExpiring &&
                !session.hideCollaboratorBanner && (
                    <CollaboratorOffboardBanner
                        collaborator={auth.user.collaborator}
                    />
                )}
            <Nav />
            <main className="bg-white" id="main-content">
                {children}
            </main>
            <Footer />
            {!noterms && (
                <TermsModal
                    open={
                        !!auth?.user &&
                        !auth?.user?.registration_consent
                            ?.development_and_or_improvement_of_ai_tools
                    }
                />
            )}
        </BaseLayout>
    );
}
